<script>
export default {
  name: 'ConclusionPayment',
  props: {
    paymentType: {
      type: String
    },
    isService: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<template>
  <div class="conclusion-payment--container">
    <div class="conclusion-payment--header">
      <h3 class="conclusion-payment--title">{{ $t(isService ? 'payment.conclusion:payment.title' : 'payment.conclusion:title') }}</h3>
    </div>
    <div class="conclusion-payment--body">
      <p class="conclusion-payment--info bold">{{ $t('payment.conclusion:info') }}</p>
      <ul>
        <li v-if="paymentType === 'bank-slip' && !isService">{{ $t('payment.conclusion.message:1') }}</li>
        <li v-if="paymentType === 'bank-slip' && !isService">{{ $t('payment.conclusion.message:2') }}</li>
        <li v-if="paymentType !== 'bank-slip' && !isService">{{ $t('payment.conclusion.message:3') }}</li>
        <li v-if="isService">{{ $t('payment.conclusion.message:4') }}</li>
        <li>{{ $t('payment.conclusion.message:5') }}</li>
      </ul>
      <v-btn class="btn transform-unset mt-4" dark :color="getPrimaryColor" @click="$router.push({ name: 'business.admin.center.payment' })">
        {{ $t('payment.conclusion.success:back') }}
      </v-btn>
    </div>
  </div>
</template>
<style lang="scss">
.conclusion-payment--container {
  .conclusion-payment--header {
    margin: 40px 0;
    h3.conclusion-payment--title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #212121;
    }
  }
  .conclusion-payment--body {
    text-align: center;
    p.conclusion-payment--info {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #777777;
      margin-bottom: 4px;
    }
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      li {
        display: block;
        position: relative;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #777777;
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: -10px;
          top: 52%;
          transform: translateY(-50%);
          height: 4px;
          width: 4px;
          background: #777;
          border-radius: 100%;
        }
      }
    }
  }
}
</style>
