<script>
import PaymentCard from '@/components/general/PaymentCard.vue'
import InputField from '@/components/general/InputField'
import SelectField from '@/components/general/SelectField'
/* eslint-disable */
export default {
  components: { PaymentCard, InputField, SelectField },
  name: 'PaymentSelection',
  props: {
    isBRL: {
      type: Boolean,
      default: false
    },
    credits: {
      type: [String, Number]
    },
    currentRates: {
      type: Object,
      default: () => {}
    },
    isServicePayment: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      selected: null,
      cardSaved: null,
      amount: this.credits,
      amountCredits: !!this.currentRates && this.credits * (this.isBRL ? this.currentRates.brl : this.currentRates.usd),
      cardInfo: {
        name: null,
        cpf: null,
        number: null,
        cvv: null,
        month: null,
        year: null
      },
      monthOptions: [
        { text: this.$t('payment.month:01'), value: '01' },
        { text: this.$t('payment.month:02'), value: '02' },
        { text: this.$t('payment.month:03'), value: '03' },
        { text: this.$t('payment.month:04'), value: '04' },
        { text: this.$t('payment.month:05'), value: '05' },
        { text: this.$t('payment.month:06'), value: '06' },
        { text: this.$t('payment.month:07'), value: '07' },
        { text: this.$t('payment.month:08'), value: '08' },
        { text: this.$t('payment.month:09'), value: '09' },
        { text: this.$t('payment.month:10'), value: '10' },
        { text: this.$t('payment.month:11'), value: '11' },
        { text: this.$t('payment.month:12'), value: '12' }
      ]
    }
  },
  methods: {
    handleRemoveCard (token) {
      this.$store.dispatch('attempDeleteCard', { token: token }).then(({ data }) => {
        this.cardSaved = null
      })
    },
    handlePaypjBankWithCard (token, extra) {
      let paymentData = {
        amount: this.isServicePayment ? Number(this.amount) : this.amountCredits,
        token: token
      }
      if (extra) {
        paymentData = {
          month: extra.month,
          year: extra.year,
          cvv: extra.cvv,
          name: extra.name,
          cpf: extra.cpf,
          ...paymentData
        }
      }
      if (this.isServicePayment) {
        paymentData = {
          description: this.description,
          ...paymentData
        }
      }

      this.$store.dispatch(this.isServicePayment ? 'attempPjBanckPayWithTokenService' : 'attempPjBanckPayWithToken', { paymentData }).then(({ data }) => {
        if (data.error) {
          this.$store.dispatch('attempNotifyTrack', { track: { event: 'pjbank_error', msg: data.error.data.msg || this.$t('payment.error:payment.title') } })
          this.$store.dispatch('attemptSetFeedbackMsg', { type: 'error', title: this.$t('payment.error:payment.title') })
          return
        }
        this.$store.dispatch('attempNotifyTrack', { track: { event: 'payment', value: paymentData.amount, currency: this.isBRL ? 'brl' : 'usd' } })
        this.$store.dispatch('attemptSetFeedbackMsg', { type: 'success', title: this.$t('payment.conclusion:payment.title') })
        this.$emit('change', this.selected)
      }).catch(() => {
        this.$store.dispatch('attempNotifyTrack', { track: { event: 'pjbank_error', msg: this.$t('payment.error:payment.title') } })
        this.$store.dispatch('attemptSetFeedbackMsg', { type: 'error', title: this.$t('payment.error:payment.title') })
      })
    },
    handlePaymentWithNewCard () {
      const paymentData = {
        name: this.cardInfo.name,
        cpf: this.cardInfo.cpf.replace('.', '').replace('.', '').replace('-', ''),
        number: this.cardInfo.number.replace(/[^0-9.]/g, ''),
        cvv: this.cardInfo.cvv,
        month: this.cardInfo.month,
        year: `${this.cardInfo.year}`
      }
      this.$store.dispatch('attempPjBanckPay', { paymentData: paymentData }).then(({ data }) => {
        if (data.status !== '201' || !data.token_cartao) {
          this.$store.dispatch('attempNotifyTrack', { track: { event: 'pjbank_error', msg: this.$t('payment.error:payment.title') } })
          this.$store.dispatch('attemptSetFeedbackMsg', { type: 'error', title: this.$t('payment.error:payment.title') })
          return
        }
        this.handlePaypjBankWithCard(data.token_cartao, paymentData)
      }).catch(() => {
        this.$store.dispatch('attempNotifyTrack', { track: { event: 'pjbank_error', msg: this.$t('payment.error:payment.title') } })
        this.$store.dispatch('attemptSetFeedbackMsg', { type: 'error', title: this.$t('payment.error:payment.title') })
      })
    },
    handlePayPjBanckBankPayment () {
      let paymentData = { amount: this.isServicePayment ? this.amount : this.amountCredits }
      if (this.isServicePayment) paymentData = { description: this.description, ...paymentData }

      this.$store.dispatch(this.isServicePayment ? 'attempPjBanckPayBankPaymenService' : 'attempPjBanckPayBankPayment', { paymentData }).then(({ data }) => {
        this.$store.dispatch('attempNotifyTrack', { track: { event: 'boleto', value: paymentData.amount, currency: this.isBRL ? 'brl' : 'usd' } })
        window.open(data.url)
        this.$emit('change', this.selected)
      }).catch(() => {
        this.$store.dispatch('attempNotifyTrack', { track: { event: 'pjbank_error', msg: this.$t('payment.error:payment.title') } })
        this.$store.dispatch('attemptSetFeedbackMsg', { type: 'error', title: this.$t('payment.error:payment.title') })
      })
    },
    handlePayPaypal () {
      paypal.Button.render({
        env: "sandbox",
        commit: true,
        style: {
          color: "silver",
          label: "paypal",
          shape: "rect",
          size: "responsive",
          tagline: false,
          height: 55
        },
        payment: async () => {
          const paymentData = { amount: this.isServicePayment ? +this.amount : +this.amountCredits }
          const data = await this.$store.dispatch('attempPaypalPayment', { paymentData }).then(({ data }) => { return data})
          await this.$store.dispatch('attempNotifyTrack', { track: { event: 'paypal', value: +paymentData.amount, currency: 'usd' } })
          return data.id
        },
        onAuthorize: async (data, actions) => {
          try {
            const res = await this.$store.dispatch('attempPaypalPaymentAuthorize', { data })
            if (res.error) {
              await this.$store.dispatch('attempNotifyTrack', { track: {event: 'paypal_error', msg: res.error.data.msg || 'PayPal error'} })
              this.$store.dispatch('attemptSetFeedbackMsg', { type: 'error', title: 'paypal_error' })
            } else {
              await this.$store.dispatch('attempNotifyTrack', { track: {event: 'payment', value: res.credit, currency: 'usd' } })
              this.$store.dispatch('attemptSetFeedbackMsg', { type: 'success', title: this.$t('payment.conclusion:payment.title') })
              this.selected = 'paypal'
              this.$emit('change', this.selected)
            }
          } catch (error) {
            console.log(error)
            await this.$store.dispatch('attempNotifyTrack', { track: { event: 'paypal_error', msg: error } })
            this.$store.dispatch('attemptSetFeedbackMsg', { type: 'error', title: this.$t('payment.error:payment.title') })
          }
        },
        onCancel: (data, actions) => {
          this.$store.dispatch('attempNotifyTrack', { track: { event: 'cancel' } })
          this.$store.dispatch('attemptSetFeedbackMsg', { type: 'error', title: this.$t('payment.cancel:payment.title') })
        }
      }, '.paypal-button-container')
    },
    handlePayStripe () {
      let newToken = null;
      const handler = StripeCheckout.configure({
        key: window.location.host == 'web.enlizt.com' ? process.env.VUE_APP_STRIPE_PROD : process.env.VUE_APP_STRIPE_DEV,
        image: 'https://assets.plooral.io/public/plooral-logo-square.png',
        locale: 'auto',
        token: async (token) => {
          newToken = token;
          try {
            const data = await this.$store.dispatch('attempStripePayment', { paymentData: { token: token.id, amount: +this.amountCredits * 100 }})
            await this.$store.dispatch('attempNotifyTrack', { track: {event: 'payment', value: data.credit, currency: 'usd' } })
            this.$store.dispatch('attemptSetFeedbackMsg', { type: 'success', title: this.$t('payment.conclusion:payment.title') })
            this.$emit('change', this.selected)
          } catch (error) {
            await this.$store.dispatch('attempNotifyTrack', { track: { event: 'stripe_error', msg: error.response.data.msg } })
            this.$store.dispatch('attemptSetFeedbackMsg', { type: 'error', title: this.$t('payment.error.creditcard') })
          }
        }
      });

      handler.open({
        amount : +this.amountCredits * 100,
        name : 'Plooral Hire Credits',
        description : this.$t('*pagamento com cartão internacional', { amount: this.amountCredits }),
        email : 'teste@test.com',
        zipCode : true,
        allowRememberMe : false,
        opened: async () => {
            
        },
        closed: async () => {
          if (!newToken) {
            await this.$store.dispatch('attempNotifyTrack', { track: { event: 'cancel' } })
            this.$store.dispatch('attemptSetFeedbackMsg', { type: 'error', title: this.$t('payment.cancel:payment.title') })
          }
          newToken = null
        }
      })
    }
  },
  watch: {
    selected () {
      this.cardInfo = {
        name: null,
        cpf: null,
        number: null,
        code: null,
        month: null,
        year: null
      }
    }
  },
  computed: {
    yearOptions () {
      const availableYears = []
      const currentYear = new Date().getFullYear()
      for (let i = 0; i <= 15; i++) {
        availableYears.push(currentYear + i)
      }
      return availableYears
    },
    textCapitalize () {
      return this.cardSaved.type.charAt(0).toUpperCase() + this.cardSaved.type.slice(1)
    }
  },
  created () {
    Promise.all([
      this.$store.dispatch('attempGetCard'),
      this.$store.dispatch('attempNotifyTrack', { track: { event: 'modal_pay', value: this.amount, currency: this.isBRL ? 'brl' : 'usd' } }),
    ]).then((data) => {
      this.cardSaved = data[0].data.card
    })
  },
  mounted() {
    if (!this.isServicePayment) {
      const hasScripts = { stripe: false, paypal: false }

      document.querySelectorAll('script').forEach(script => { 
        if (script.getAttribute('src') === 'https://checkout.stripe.com/checkout.js') script.remove(); hasScripts.stripe = true
        if (script.getAttribute('src') === 'https://www.paypalobjects.com/api/checkout.js') script.remove();  hasScripts.paypal = true
        if (script.getAttribute('src') === 'https://www.paypal.com/tagmanager/pptm.js?id=localhost&source=checkoutjs&t=xo&v=4.0.336') script.remove();  hasScripts.paypal = true
      })

      if (this.selectedWorkspace.region === 'USA' && hasScripts.stripe) {
        const stripe = document.createElement('script')
        stripe.setAttribute('src','https://checkout.stripe.com/checkout.js')
        document.head.appendChild(stripe)
      }

      if (hasScripts.paypal) {
        const paypal = document.createElement('script')
        paypal.setAttribute('src','https://www.paypalobjects.com/api/checkout.js')
        paypal.setAttribute('data-version-4','')
        document.head.appendChild(paypal)
        setTimeout(()=> { 
          this.handlePayPaypal()
        }, 200)
      }
    }
  },
}
</script>
<template>
  <div class="payment-selection--container">
    <div class="payment-selection--header">
      <h3 class="payment-selection--title">{{ $t('payment.selection:title') }}</h3>
      <p class="payment-selection--sub">{{  isServicePayment ? $t('payment.selection:other.payment', { value: amount }) : $t('payment.selection:sub', { value: amount, credits: credits }) }}</p>
    </div>
    <div class="payment-selection--options">
      <div
        v-if="!isServicePayment && !isBRL"
        class="stripe-button-container mb-4"
        :selected="selected === 'stripe'"
        @click="selected = 'stripe'; handlePayStripe()"
      >
      <div class="striper-button">
        <v-icon class="mr-2" size="25px">mdi-credit-card-outline</v-icon>
        <p class="payment-card--title mb-0">Credit Card</p>
      </div>
      </div>
      <div
        v-if="!isServicePayment && !isBRL"
        class="paypal-button-container"
        :selected="selected === 'paypal'"
      ></div>
      <payment-card
        v-if="isBRL"
        :selected="selected === 'bank-slip'"
        icon="mdi-barcode"
        paymentType="bank-slip"
        @click="selected = 'bank-slip'; handlePayPjBanckBankPayment()"
      ></payment-card>
      <payment-card
        v-if="!!cardSaved && isBRL"
        :selected="selected === cardSaved.token"
        icon="mdi-credit-card-clock-outline"
        paymentType="saved-card"
        :titleParams="{ holder: textCapitalize, final: cardSaved.lastDigits }"
        deleteable
        @removeSavedCard="handleRemoveCard(cardSaved.token)"
        @click="selected = cardSaved.token; handlePaypjBankWithCard(cardSaved.token, false)"
      ></payment-card>
      <payment-card
        v-if="isBRL"
        :selected="selected === '1'"
        icon="mdi-credit-card-outline"
        paymentType="new-card"
        @click="selected = '1'"
      >
        <div class="payment--input-wrapper" v-if="selected === '1'">
          <input-field v-model="cardInfo.name" dense outlined hide-validation :label="$t('payment.card:card.name')"></input-field>
          <input-field v-model="cardInfo.cpf" dense outlined hide-validation :label="$t('payment.card:card.cpf')" mask="###.###.###-##"></input-field>
          <div class="payment--card-info">
            <input-field v-model="cardInfo.number" dense outlined hide-validation :label="$t('payment.card:card.number')" mask="#### #### #### ####"></input-field>
            <input-field v-model="cardInfo.cvv" dense outlined hide-validation :label="$t('payment.card:card.code')" mask="###"></input-field>
          </div>
          <div class="payment--validate">
            <p>{{ $t('payment.card:card.validate') }}</p>
            <div class="payment--validate-fields">
              <select-field v-model="cardInfo.month" :items="monthOptions" dense outlined hide-validation :label="$t('payment.card:card.month')"></select-field>
              <select-field v-model="cardInfo.year" :items="yearOptions" dense outlined hide-validation :label="$t('payment.card:card.year')"></select-field>
            </div>
          </div>
          <div class="payment--actions">
            <div class="payment--action-left">
              <v-img src="/assets/images/pj-bank.png" height="20px" width="138px"></v-img>
              <p>{{ $t('payment.security:footer') }}</p>
            </div>
            <div class="payment--action-right">
              <v-btn class="btn transform-unset" dense :color="getPrimaryColor" dark @click="handlePaymentWithNewCard()">{{ $t('global:confirm') }}</v-btn>
            </div>
          </div>
        </div>
      </payment-card>
    </div>
 </div>
</template>
<style lang="scss">
.payment-selection--container {
  .payment-selection--header {
    margin: 40px 0;
    h3.payment-selection--title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #212121;
    }
    p.payment-selection--sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #A0A0A0;
    }
  }
  .payment-selection--options {
    .stripe-button-container {
      text-align: center;
      display: flex;
      align-content: center;
      justify-content: center;
      .striper-button {
        flex: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #eee;
        color: #111;
        height: 55px;
        max-width: 750px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          box-shadow: inset 0 0 100px 100px RGB(0 0 0 / 5%)
        }
        p {
          font-weight: 700;
          font-size: 18px;
        }
      }
    }
    .payment--input-wrapper {
      margin-top: 20px;
      .input-field--wrapper {
        margin-bottom: 16px;
      }
      .payment--card-info {
        display: flex;
        justify-content: space-between;
        > :nth-child(1) {
          width: 100%;
        }
        > :nth-child(2) {
          margin-left: 16px;
          width: 25%;
        }
      }
      .payment--validate {
        .payment--validate-fields {
          display: flex;
          > :nth-child(1) {
            margin-right: 16px;
          }
        }
      }
      .payment--actions {
        margin: 0 -16px -16px;
        padding: 16px 24px;
        background: #FAFAFA;
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .payment--action-left {
          p {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #A0A0A0;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
