<script>
import ConfirmationModal from './ConfirmationModal.vue'
export default {
  components: { ConfirmationModal },
  name: 'PaymentCard',
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    deleteable: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    },
    paymentType: {
      type: String
    },
    titleParams: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      showRemovalModal: false
    }
  }
}
</script>
<template>
  <div class="payment-card--container" :class="{'active': selected}" @click.stop="$emit('click')">
    <div class="payment-card--body">
      <div class="payment-card--left-wrapper">
        <div class="payment-card--icon-wrapper">
          <v-icon color="#454545" size="24px">{{ icon }}</v-icon>
        </div>
        <div class="payment-card--info">
          <div class="payment-card--title-wrapper">
            <p class="payment-card--title">{{ $t(`payment.card:payment.type.${paymentType}.title`, titleParams) }}</p>
            <v-icon v-if="deleteable" size="18px" @click.stop="showRemovalModal = true">mdi-delete</v-icon>
          </div>
          <span class="payment-card--description">{{ $t(`payment.card:payment.type.${paymentType}.description`) }}</span>
        </div>
      </div>
      <div class="payment-card--right-wrapper">
        <div class="payment-card--selected">
        </div>
      </div>
    </div>
    <div class="payment-card--slot-wrapper">
      <slot></slot>
    </div>
    <confirmation-modal
      :show="showRemovalModal"
      :title="$t('payment.card.remove:title')"
      :description="$t('payment.card.remove:info')"
      :confirmText="$t('global:remove')"
      :btnConfirmText="false"
      @confirm="$emit('removeSavedCard')"
      :cancelText="$t('global:cancel')"
      @cancel="showRemovalModal = false"
    ></confirmation-modal>
  </div>
</template>
<style lang="scss">
.payment-card--container {
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-bottom: 16px;
  cursor: pointer;
  box-shadow: 0px 4px 8px 3px rgba(206, 206, 206, 0.0), 0px 1px 3px rgba(206, 206, 206, 0.0);
  transition: box-shadow ease 200ms;
  &:hover {
    box-shadow: 0px 4px 8px 3px rgba(206, 206, 206, 0.3), 0px 1px 3px rgba(206, 206, 206, 0.15);
    border-radius: 4px;
  }
  .payment-card--body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .payment-card--left-wrapper {
      display: flex;
      align-items: center;
      .payment-card--icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
      }
      .payment-card--info {
        display: flex;
        flex-direction: column;
        text-align: left;
        .payment-card--title-wrapper {
          display: flex;
          align-items: flex-start;
          margin-bottom: 7px;
          p.payment-card--title {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            color: #272730;
            margin-top: 1px;
            margin-bottom: 0px;
          }
          .v-icon {
            margin-left: 8px;
            color: #454545;
            cursor: pointer;
            &:hover {
              color: red;
            }
          }
        }
        span.payment-card--description {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 14px;
          color: #A0A0A0;
        }
      }
    }
    .payment-card--right-wrapper {
        display: flex;
        align-items: center;
      .payment-card--selected {
        position: relative;
        width: 20px;
        height: 20px;
        border: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 999px;
      }
    }
  }
  &.active {
    .payment-card--body {
      .payment-card--right-wrapper {
        .payment-card--selected {
          border-color: $primary-medium;
          &::before {
            content: '';
            display: block;
            position: absolute;
            height: 10px;
            width: 10px;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            background: $primary-medium;
            border-radius: 100px;
          }
        }
      }
    }
  }
}
</style>
